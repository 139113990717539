.pri{
    margin-left: 10%;
}
.det{
    width: 90%;
}
.bute{
   margin-right: 100%;
   margin-top: -10%;
   font-size: 100%;
}