/* Cart.css */
.cart {
    max-width: 400px;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .cart h2 {
    margin-top: 0;
  }

  .cart-items {
    list-style: none;
    padding: 0;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .total {
    text-align: right;
  }
  
  button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .nombre {
    border: none;
    width: 16%; 
    height: 10%;
    color: black;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1;
  cursor: pointer;
}

.title{
  color: green;
}

.tit{
  color: green;
  margin-left: 10%;
}

.sis{
  margin-left: 74%;
  margin-top: -8.5%;
}

.nombre:focus {
  outline: none !important;
  box-shadow: none !important;
}
  
  .delete-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-right: 1%;
    font-size: 70%;
    color: inherit;
    align-items: center;
  }
