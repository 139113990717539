.container-fluid{
    margin-bottom: 10%;
}

.piz{
    position:relative;
    left: 10%;
    margin-top: 2%;
    width: 65%;
}
.pani{
    color: white;
    width: 30%;
    margin-left: 10%;
    cursor: pointer;
    transition: color 0.3s;

}
.tat{
    text-decoration: underline;
    
}

.panier.clicked {
    color: green; /* Nouvelle couleur de l'icône après le clic */
  }
.panie{
    position: relative;
    color:green;
    font-size: 200%;
    cursor: pointer;
    left: -90%;
    margin-top: -95%;
}
.pe{
    margin-left: 10%;
    margin-top: -6%;
    font-size: 110%;
}
.nom{
    color:red;
    margin-left: 10%;
    margin-top: 1%;
    font-size: 150%;
}
.desi{
    margin-left: 10%;
    margin-top: -6%;
    font-size: 110%;
    
}

  .logo{
    position: absolute;
    height: 110%;
    bottom: 1%;
    left: 0%;
}


.scrollable {
    max-height: calc(100vh - 100px); /* Ajustez cette valeur en fonction de votre en-tête et pied de page */
}

.scrollable::-webkit-scrollbar {
    width: 8px; /* Largeur de la barre de défilement */
}

.scrollable::-webkit-scrollbar-track {
    background-color: transparent; /* Couleur de fond de la piste */
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #888; /* Couleur de la poignée de la barre de défilement */
    border-radius: 4px; /* Coins arrondis de la poignée */
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Couleur de la poignée de la barre de défilement au survol */
}
/* .carde{
    flex-direction: column;
    display: flex;
    border: none;
}

.custom-card {
    border: none;
    margin-left: 5%;
    background-color: black;
} */

.btne{
    position: relative; 
    margin-top: 4%;
    position: fixed;
    background-color: white;
    z-index: 1;
    font-family: 'Times New Roman', Times, serif;
    font-size: 130%;
    padding-left: 94%;
}

.App {
    font-family: sans-serif;
    text-align: center;
  }

  .titre .modal-content {
      font-size: 100%;
      text-align: center;
      color: green;
  }
  
  .special_modal .modal-content {
    background-color:  #fff;
    color:black;
  }

  .Modal_Login{
    display: flex;
     flex-direction:row;
}
.Modal_Login_header{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    border-bottom: none !important;
}

.Close{
    cursor: pointer;
    color: red;
    margin-top: -4%;
}

.inputbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;

  }
  .inputbox input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
  }

  .inputbox select{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
  }

  .option{
      background-color: #000;
      color: #fff;
  }
  .footer{
      border-top: none !important;
  }
  .command{
   padding-right: 10%;
  }

.annuler{
    background-color: #888;
}
.pant{
   margin-right: 5%;
}

@media screen and (max-width:700px) {
    .option{
        background-color: #000;
        color: #fff;
        font-size: 60%;
    }
    .categorie{
        position: relative;
        color:#F4AD23;
        margin-top: 18%;
        margin-left: 25%;
        width: 100%;
    }
    .btn{
        position: relative; 
        margin-top: 15%;
        margin-left: -5%;
        position: fixed;
        z-index: 1;
    }
    .command{
        right: 20%;
       }
    .panier{
        color: green;
        font-size: 100%;
        margin-left: 55%;
        margin-top: -15%;
        cursor: pointer;
        transition: color 0.3s;
    
    }
    
    
}


   

