.contact{
    padding-top: 6%;
}
.nou{
    text-align: center;
    color: red;
}
.cont{
    background-image: url('./PRO.jpg'); 
    padding: 3%;
    padding-bottom: 8%;
}
.cardes{
    display : flex;
    border: 2px solid #CCC;
    height: 30%;
    background-color: rgba(255, 255, 255, 0.8);
    width: 60%;
    margin-left: 20%;
    text-align: center;
}
.inpn{
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    height: 80%;
    width: 76%;
    margin-top: 8%;
    
}
.in{
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    width: 51%;
    height: 85%;
    margin-top: 8%;
    margin-left: 41%;
}
.btn-en{
    margin-top: 10%;
    width: 30%;
    height: 50%;
    margin-left: 20%;
}
.dv{
    margin-left: 17.5%;
}