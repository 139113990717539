.top{
    padding-top: 7%;
}
.util{
    padding-top:-10%;
    font-size: 170%;
    padding-left: 35%;
    color: blue;
}
.rech{
    width: 50%;
    margin-left: 15%;
}
@media screen and (max-width: 700px) {
    .top{
        padding-top: 17%;
    } 
    .util{
        padding-top:-10%;
        font-size: 130%;
        padding-left: 25%;
        color: blue;
        padding: 3%;
    }
    .rech{
        width: 65%;
        margin-left: 3%;
    }
}