.bloc{
    margin-left: -2%;
    margin-right: -6%;
}

.container_footer{
    background: #1b1735;
    margin-top: 1px;
    padding-top: 40px;
    color: #fff;
}

.container_footer1 .logo div{
    
    font-size: 100%;
    padding-top: 15%;
    text-align: center;
    line-height: 120%;
}

.container_footer1 .logolong{
    width: 300px;
    height: 70px;
    margin-bottom: 10px;
    margin-left: -5%;
}
.mg{
    margin-top: -1%;
    margin-left: -10%;
    text-align: center;
}

.lien_logo{
    display: flex;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 1%;
    /* justify-content: space-around; */
}

.lien_logo a{
    display: flex;
    font-size: 150%;
    color: white;
}

.lien_logo span{
    padding-top: -2%;
    padding-bottom: 2%;
    /* margin-left: 3%; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.lien_logo span.c{
    padding-top: -2%;
    padding-bottom: 2%;
    margin-left: 0.8%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.lien_logo span.b{
    padding-top: -2%;
    padding-bottom: 2%;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.container_footer1 .contact img{
    width: 28px;
    margin-right: 5px;
    display: flex;
}

.container_footer1 .contact .lieu,
.container_footer1 .contact .lot,
.container_footer1 .contact .phone,
.container_footer1 .contact .mail{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.container_footer1 .contact .lieu{
    display: flex;
    color: rgb(207, 202, 202);
}

.container_footer1 .contact .lot{
    display: flex;
    color: rgb(207, 202, 202);
}

.container_footer1 .contact{
    font-size: 16px;
}

.container_footer1 .service p{
    margin-left: 20%;
}
.container_footer1 .ressource p{
    margin-left: 2%;
}

.container_footer1 .service a{
    text-decoration: none;
    margin-left: 20%;
    color: #fff;
    display: block;
    margin-top: 10px;
    font-size: 16px;
}
.container_footer1 .ressource a{
    text-decoration: none;
    margin-left: 2%;
    color: #fff;
    display: block;
    margin-top: 10px;
    font-size: 16px;
}

.container_footer1 .service p,
.container_footer1 .ressource p,
.container_footer1 .contact p{
    font-size: 21px;
    font-weight: bold;
}

.container_footer2{
    width: 100.9%;
    font-size: 14%;
    text-align: center;
    background-color: rgb(109, 212, 109);
    color: #fff;
}

.container_footer2 .copyright{
    padding-top: 1%;
    font-size: 500%;
}

.condition{
    margin-left: 45%;
    padding-bottom: 1%;
}

.condition a{
    color: #fff;
    font-size: 80%;
    margin-left: 2vw;
    transition: 0.4s;
    text-decoration: none;
}

.condition a:hover{
    color: #2911ff;
    margin-left: 2vw;
}

.terme{
    margin-left: 4%;
    padding-bottom: 1%;
}

.terme a{
    color: #fff;
    font-size: 80%;
    margin-left: 2vw;
    transition: 0.4s;
    text-decoration: none;
}

.terme a:hover{
    color: #2911ff;
    margin-left: 2vw;
}
.Footer{
    /* position: absolute;
    bottom:0; */
    width: 100%;
    /* height: 2.5rem; */
}

@media screen and (max-width:700px){
    .condition{
        margin-left: 15%;
        padding-bottom: 1%;
    }
    .container_footer1 .logolong{
        width: 300px;
        height: 60px;
        margin-bottom: 35px;
        margin-left: 10%;
    }
    .mg{
        margin-top: -8%;
        margin-left: -4%;
        text-align: center;
    }
    .aprop{
        margin-left: -12%;
        margin-top: 2%;
    }
    .pour{
        margin-left: 45%;
        margin-top: -33%;
    }
    .cord{
        margin-top: 5%;
        margin-left: 25%;
    }
    .container_footer2{
        width: 103%;
        font-size: 5%;
        text-align: center;
        background-color: rgb(109, 212, 109);
        color: #fff;
    }
    .container_footer2 .copyright{
        padding-top: 1%;
    }
   .cop{
    font-size: 300%;
   }
}