.foote{
    width: 111%;
}
.mat{
    position: relative;
    width: 90%;
    left: 5%;
    padding-top: 3%;
}
.app{
    width: 90%;
}
.miharo{
    color:greenyellow;
    font-size: 350%;
    padding-left: 15%;
    padding-top: 5%;
}
.slog{
    color: rgb(141, 33, 33);
    margin-top: -4%;
    margin-left: 17%;
    font-size: 150%;
    font-family: Italic;
}
.pp{
    padding-top: 35%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    padding-right: 10%;
    color:black;
}
.btn-ton{
    border-color:red;
    background-color: transparent;
    margin-left: 30%;
    margin-top: 4%;
    color: black;
    font-family: 'Times New Roman', Times, serif;
}
.bou{
    padding-bottom: 5% ;
}
.tonga{
    padding-top: 5%;
    width: 111%;
    background-image: url('./col.jpg'); 
    background-size: cover;
}
.eto{
    padding-top: 15%;
    padding-bottom: 15%;
    text-align: center;
    color: white;
}
.logos{
    width: 65%;
    margin-top: -25%;
    margin-bottom: 5%;
}

@media screen and (max-width:700px){
    .logos{
        width: 65%;
        margin-top: -25%;
        margin-bottom: 5%;
        margin-left: 7%;
    }
    .pp{
        padding-top: 35%;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: large;
        padding-right: -0%;
        padding-left: 6%;
        color:black;
    }
}
