.joust{
    margin-left: 4%;
    width: 32%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    text-indent: 10%;
}

@media screen and (max-width:700px) {
  .joust{
      margin-left: 7.5%;
      width: 40%;
      text-indent: 10%;
      padding: 4%;
      padding-top: 2%;
      padding-bottom: 1%;
  }
}