.p{
    font-size: 15px;
    color: solid;
   }
   .p1{
    font-size: 20px;
    color: blue;
    position: absolute;
    top: 2%;
   }
   .btn-ajout{
    margin-right: 20%;
    margin-top: -8%;
   }
    .form{
      margin-top: 10px;
      margin-bottom: 5px;
     
    }
    .error-message{
      position: relative;
      font-size: 30px;
      margin-top: -45px;
      padding-left: 200px;
      color: red;
    }
    .inputbox {
      position: relative;
      border-bottom : 2px solid #e0d9d9;
      width: 95%;
  }
  .inputbox input{
      border: none;
      border-bottom: 0px solid ; /* Start with a transparent solid border-bottom */
      outline: none; /* Remove the default focus outline */
      padding: 5px 0; /* Adjust padding as needed */
      background-color: #948989;
      transition: border-bottom 0.3s ease; 
      flex: 1; /* Take up the remaining space */
      padding: 5px; /* Adjust the input padding as needed */
      font-size: 16px; /* Adjust the input font size as needed */
  }
  .inputbox {
      display: flex; /* Align label and input horizontally */
      align-items: center; /* Center items vertically within the flex container */
      margin-bottom: 10px; /* Add some space between input boxes */
      color: black;
    }
    
    .inputbox label {
      flex: 0 0 150px; /* Set the width of the label (adjust as needed) */
      margin-right: 10px; /* Add some space between label and input */
    }
    
    .inputbox {
      position: relative;
      margin-bottom: 10px;
    }
    .select-container {
      position: relative;
    }
    
    .select-container label {
      position: absolute;
      top: -25px; /* Adjust as needed to position the label */
      background-color: #fff;
      padding: 0 5px;
      font-size: 14px;
      color: #777;
    }
    
    .select-container select {
      padding: 5px;
      font-size: 16px;
      border: 2px solid #ccc; /* Normal border color */
      border-radius: 5px;
      width: 100%; /* Make the select element take the full width */
      appearance: none; /* Remove default styles on some browsers */
    }
    
    /* Style the label when the select is focused or has a value */
    .select-container select:focus + label,
    .select-container select:not(:placeholder-shown) + label {
      top: -28px; /* Adjust as needed for the focused or value state */
      left: 5px;
      font-size: 12px;
      background-color: #f9f9f9;
      padding: 0 5px;
      color: #555;
    }
    .opp{
        color: blue;
        font-size: 170%;
        margin-left: 5%;
        margin-top: -0%;
        
    }
    @media screen and (max-width:700px) {
        .date{
            margin-top: 10%;
           }
        .reche{
          width: 80%;
          padding: 2%;
          margin-left: 2%;
        }   
    }