.view{
    color: green;
}
textarea{
    margin-left: 10%;
    width: 90%;
    height: 60%;
}
label{
    margin-left: 10%;
    margin-top: 5%;
}
.uti{
    color: blue;
    padding-left: 20%;
}
.envoyer{
    margin-left: 20%;
    margin-top: 35%;
    background-color: blue;
    color: white;
    border: none;
    width: 50%;
    height: 30%;
}
/* .bit{
    position: relative;
    color: rgb(68, 14, 65);
    font-size: 700%;
    left: 25%;
} */
.data{
    width: 90%;
    border: 1px solid #ccc;
    margin-left: 5%;
    border-bottom: none;
}
@media screen and (max-width:700px){
    .uti{
        color: blue;
        padding-left: 6%;
        padding-bottom: 2%;
    }
    textarea{
        margin-left: 7%;
        width: 85%;
        height: 60%;
    }
    .envoyer{
        margin-left: 35%;
        margin-top: 5%;
        background-color: blue;
        color: white;
        border: none;
        width: 30%;
        height: 60%;
    }
}