.nave{
    display: flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 0 0 0 0;
    width: 100%;
    position: fixed;
    z-index: 1;  
    right: -0%;
}
#navbare{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5%;
    position: relative; /* Pour permettre le positionnement des ::after */
    z-index: 1;
}

#navbare li {
    list-style:none;
    padding: 0 20px;
    position: relative;
    font-size: 1.2rem;
    font-weight: 600;
    color: #867e7e;
}

.to_link{
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: #867e7e;
    transition: 0.3s ease-in-out;

}

#navbare li .to_link:hover,
#navbare li .to_link:active {
    color: rgb(72, 40, 214);
} 

#navbare li .to_link :hover ::after,
#navbare li .to_link :active ::after{
    content:"";
    width: 30%;
    height: 2px ;
    background: rgb(72, 40, 214);
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile{
    display: none;
}
.logoStyles{
    width: 120px;
    padding-top: 10%;
}

.responsive-icon {
    @media screen and (max-width: 1060px) {
      width: 100px; /* Ajustez la largeur en fonction de vos besoins */
    }
  }

@media screen and (max-width:1060px){
    #navbare {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 1.5%;
        position: fixed;
        padding: 0%;
        margin: 0%;
        box-sizing: border-box;
        top: 36px;
        right: -185px;
        width: 185px;
        height: 100vh;
        background: white;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbare li{
        margin-bottom: 25px;
    }
    #navbare.active{
        right: 0px;
    }

    #mobile{
        display: block;
    }
    .logoStyles{
        width: 85px;
        height:30px;
    }
}
.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .dropdown-contente {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 5%;
  }
  
  .dropdown-contente .to_link {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    
  }
  
  .dropdown-contente .to_link:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-contente {
    display: block;
  }






