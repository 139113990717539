.sous_container_partenaire{
    box-shadow: 0px 0px 1px 1px #ccc;
}

.photo_partenaire img{
    width: 100%;
}

.contenu_partenaire div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenu_partenaire .titres{
    font-weight: bold;

}

.contenu_partenaire .domaine{
    margin-top: -10px;

}
.par{
   font-size: 150%;
   padding-top:5%;
}
.pr{
    font-size: 200%;
    padding-top: 5%;
}
.bur{
    font-size: 150%;
    height: 100%;
}

@media (max-width: 700px) {
    .piza{
        font-size: 100%;
    }
    .pr{
        font-size: 200%;
        margin-top: 10%;
    }
    .pour{
        margin-left: 30%;
        margin-top: -49%;
    }
    
}


