.icon{
    border: none;
    background-color: white;
    font-size: 120%;
}
.view{
    color: green;
}
.edit{
    color: blue;
}
.delete{
    color: red;
}
.btn{
    position: relative;
    margin-top: -0%;
    left: 80%;    
}
.but{
    border: #f0f0f0;
    background-color: rgb(15, 15, 240);
    color: white;
    width: 30%;
    font-size: 115%;
    margin-right: 80%;
    margin-top: -2%;
}
.bu{
    border: #f0f0f0;
    background-color: rgb(255, 0, 0);
    color: white;
    width: 30%;
    margin-right: 80%;
    margin-top: -13%;
    font-size: 70%;
}
.fer{
    width: 30%;
    font-size: 105%;
    margin-right: 105%;
    margin-top: -2%;
}
.fe{
    width: 30%;
    margin-right: 125%;
    margin-top: -3%;
    font-size: 70%;
}
.del{
    font-size: 200%;
    color: red;
}
.liste{
    margin-top: -0%;
    color: blue;
    font-size: 170%;
    margin-left: 5%;
}
.inputboxes {
    position: relative;
    border-bottom : 2px solid #e0d9d9;
    display: flex; 
    align-items: center; 
    margin-bottom: 10px;
    width: 90%;
   
}
.inputboxes input{
    border: none;
    border-bottom: 0px solid ; 
    outline: none; 
    padding: 5px 0; 
    background-color: #f0f0f0;
    transition: border-bottom 0.3s ease;
    flex: 1; 
    padding: 5px;
    font-size: 16px; 
}

.inputboxes select {
    border: none;
    border-bottom: 0px solid ; 
    outline: none; 
    padding: 5px 0; 
    background-color: #f0f0f0;
    transition: border-bottom 0.3s ease;
    flex: 1; 
    padding: 5px;
    font-size: 16px;  
  }
.footer{
    border-top: none !important;
}
.header{
    border-bottom: none !important;
}
.profile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px; /* Ajustez la taille en fonction de vos besoins */
    height: 150px; /* Ajustez la taille en fonction de vos besoins */
    overflow: hidden;
    border-radius: 50%; /* Rend le conteneur de l'image circulaire */
    border: 2px solid #ccc; /* Ajoute une bordure */  
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajuste la taille de l'image pour remplir le conteneur circulaire */
    border-radius: 50%; /* Rend l'image de profil circulaire */
  }

  @media screen and (max-width:700px) {
    .pad{
        padding: 5%;
        width: 85%;
    }
    .btn{
        position: relative;
        margin-top: -0%;
        left: 72%;    
    }
    .bu{
        border: #f0f0f0;
        background-color: rgb(255, 0, 0);
        color: white;
        width: 30%;
        margin-right: 90%;
        margin-top: -9.5%;
        font-size: 70%;
    }
  }
  
