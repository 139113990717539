.jo{
    margin-left: 6%;
    width: 32%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    text-indent: 10%;
}

@media screen and (max-width:700px) {
  .jo{
      margin-left: 11%;
      width: 40%;
      text-indent: 10%;
      padding: 4%;
      padding-top: 1%;
      padding-bottom: 1%;
  }
  option{
    font-size: 60%;
  }
}