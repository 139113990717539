.nav{
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 0 0 0 0;
    width: 100%;
    position: fixed;
    z-index: 1;  
    right: -0%;
}
#navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5%;
    position: relative; /* Pour permettre le positionnement des ::after */
    z-index: 1;
}

#navbar li {
    list-style:none;
    padding: 0 20px;
    position: relative;
    font-size: 1.2rem;
    font-weight: 600;
    color: #867e7e;
}

.link_to{
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: #867e7e;
    transition: 0.3s ease-in-out;

}

#navbar li .link_to:hover,
#navbar li .link_to:active {
    color: rgb(29, 230, 46);
} 

#navbar li .link_to :hover ::after,
#navbar li .link_to:active ::after{
    content:"";
    width: 30%;
    height: 2px ;
    background: rgb(29, 230, 46);
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile{
    display: none;
}

.responsive-icon {
    @media screen and (max-width: 1060px) {
      width: 100px; /* Ajustez la largeur en fonction de vos besoins */
    }
  }

@media screen and (max-width:1060px){
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 1.5%;
        position: fixed;
        padding: 0%;
        margin: 0%;
        box-sizing: border-box;
        top: 50px;
        right: -185px;
        width: 185px;
        height: 100vh;
        background: white;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbar li{
        margin-bottom: 25px;
    }
    #navbar.active{
        right: 0px;
    }

    #mobile{
        display: block;
    }
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content .link_to {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content .link_to:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

.publication-dropdown{
    z-index: 1;
}


