.parametre{
    font-size: 150%;
    color: blue;
    text-align: center;
}
.parametr{
    font-size: 150%;
    color: blue;
    padding-left: 27%;
}
.tabl{
    border: 1px solid #ccc;
    border-bottom: none;
    width: 80%;
    margin-left: 9%;
}
.jout{
    margin-left: 4%;
}
.jou{
    margin-left: 2%;
    width: 32%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    text-indent: 10%;
}
.lab{
    margin-left: 10%;
}
.btn-part{
  margin-right: 50%;
  margin-top: 4%;
}
@media screen and (max-width:700px) {
    .jout{
        margin-left: 6%;
        width: 41%;
    }
    .jou{
        margin-left: 2%;
        width: 40%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
        text-indent: 10%;
    }
    .btn-part{
        margin-right: 30%;
        margin-top: 4%;
        padding-bottom: 2%;
      }
      .tabl{
        border: 1px solid #ccc;
        border-bottom: none;
        width: 85%;
        margin-left: 9%;
    }
}
