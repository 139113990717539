.formulaire{
  padding-top: 10%;
  padding-bottom: 3%;
}
.card_formu{
  width: 40%;
  margin-top: 5%;
  margin-left: 5%;
  padding-bottom: 5%;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  font-family: 'Times New Roman', Times, serif;
  text-indent: -6%;
}
.form_inscri{
  width: 99%;
}
.btn_inscr{
  margin-top: 5%;
  text-align: center;
}
.inscrir{
  width: 70%;
}
.icon-inside-input {
  position: absolute;
  right: 27%; 
  top: 68.5%; 
  transform: translateY(-50%); 
  cursor: pointer;
  z-index: 2;
}
.icone-inside-input {
  position: absolute;
  right: 7%; 
  top: 57.5%; 
  transform: translateY(-50%); 
  cursor: pointer;
  z-index: 2;
}
