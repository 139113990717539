.App {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    padding-top: 1%;
  }
  
  .notification-list {
    width: 35%;
    border-right: 1px solid #ccc;
    padding-right: 20px;
  }
  
  .notification-list h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .notification-list ul {
    list-style: none;
    padding: 0;
  }
  
  .notification-list li {
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;
  }
  
  .notification-list li:hover {
    background-color: #f0f0f0;
  }
  
  .notification-details {
    flex: 1;
    padding: 0 20px;
  }
  
  .notification-details h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .notification-details p {
    font-size: 1rem;
    color: #555;
  }
  .not{
    padding-top: 5%;
    color: blue;
    padding-left: 5%;
  }
  @media screen and (max-width:700px) {
    .App {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px;
      padding-top: 5%;
    }
    .notification-list h1 {
      font-size:120%;
      margin-bottom: 10px;
    }
    .not{
      padding-top: 10%;
      color: blue;
      padding-left: 5%;
    }
  }
  
  