.card_conne{
    margin-top: 5%;
    margin-left: 5%;
    padding-bottom: 5%;
    width: 99%;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    font-family: 'Times New Roman', Times, serif;
}
.inputboxess{
    position:absolute;
    width: 20%;
    border-bottom: 1px solid #c7bbbb; 
    margin: 10% 0;
    margin-left: 10%;
    margin-bottom: 5%;
    margin-top: 17%;
}
.inputboxess input{
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0 35px 0 5px; 
}
.inputboxe{
    position:absolute;
    width: 20%;
    border-bottom: 1px solid #c7bbbb; 
    margin: 10% 0;
    margin-left: 10%;
    margin-bottom: 5%;
    margin-top: 22%;
}
.inputboxe input{
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0 35px 0 5px;
}

.bt{
    position: absolute;
    right: 31%;
    margin: 1%;
    margin-top: 28%;
    display: flex;

}
.image{
    position: absolute;
    max-height: 36%;
    left: 6%;
    top: 35%;
    display: flex;

}
.bi{
    position: absolute;
    color: rgb(68, 14, 65);
    font-size: 900%;
    left: 66%;
    top: 10%;
}
.compte{
    color: black;
}

.connect{
    color: blue;
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.P2{
    position: absolute;
    margin-left: 14%;
    font-size: 11px; 
    top: 68%; 
}


@media screen and (max-width:1060px)  {
    .inputboxes{
        position:absolute;
        width: 40%;
        border-bottom: 1px solid #c7bbbb; 
        margin: 10% 0;
        margin-left: 50%;
        margin-top: 60%;
    }
    .inputboxes input{
        background: transparent;
        border: none;
        outline: none;
        font-size: 1em;
        padding: 0 35px 0 5px; 
    }
    .inputboxe{
        position:absolute;
        width: 40%;
        border-bottom: 1px solid #c7bbbb; 
        margin: 10% 0;
        margin-left: 50%;
        margin-top: 75%;
    }
    .inputboxe input{
        background: transparent;
        border: none;
        outline: none;
        font-size: 1em;
        padding: 0 35px 0 5px;
    }
    
    .bt{
        position: absolute;
        right: 40%;
        margin: 1%;
        margin-top: 94%;
        display: flex;
    
    }
    .image{
        position: absolute;
        max-height: 7%;
        left: 6%;
        top: 40%;
        display: flex;
    
    }
    .bi{
        position: absolute;
        color: rgb(68, 14, 65);
        font-size: 650%;
        left: 57%;
        top: 17%;
    }
    .compte{
        color: black;
    }
    
    .connect{
        color: blue;
        cursor: pointer;
        border: none;
        background-color: transparent;
    }
    
    .P2{
        margin-left: 47%;
        font-size: 11px;  
        top: 60%;
    }
}
