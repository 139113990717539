.cot{
    padding-top: 6%;
    font-family: 'Times New Roman', Times, serif;
  }
.carde_in{
  width: 40%;
  margin-top: 1%;
  margin-bottom: 4%;
  margin-left: 5%;
  border: 1px solid #ccc;
  text-indent: -6%;
}
.formulai{
    padding-top: 4%;
  padding-bottom: 2%;
}
.btn_opport{
  width: 60%;

}
.opport{
    margin-top: 5%;
    margin-left: 12%;
    margin-bottom: 5%;
}
.retour{
    background-color: rgb(114, 110, 110);
    width: 60%;
}