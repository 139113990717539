.joutes{
    margin-left: 15%;
}
.btn-partes{
    margin-right: 41%;
    margin-top: 4%;
  }
  .joue{
    margin-left: 4%;
    width: 32%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    text-indent: 10%;
}
.paramet{
    font-size: 150%;
    color: blue;
    padding-left: 27%;
}
@media screen and (max-width:700px) {
    .joutes{
        margin-left: 21%;
        width: 41%;
    } 
    .btn-partes{
        margin-right: 15%;
        margin-top: 4%;
        padding-bottom: 2%;
      }
    .joue{
        margin-left: 3%;
        width: 40%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
        text-indent: 10%;
    }  
    .paramet{
        font-size: 150%;
        color: blue;
        padding-left: 20%;
    }
}