.body_compte{
    padding-top: 6%;
    padding-bottom: 5%;
}
.cardess{
    border: 1px solid #ccc;
    margin-left: 25%;
    width: 50%;
    height: 50%;
    box-shadow: 5px 5px 10px 2px rgba(95, 95, 95, 0.24);
}
.liste_info{
    margin-top: 5%;
    margin-left: 10%;
}
.info_perso{
    padding-top: 3%;
    text-align: center;   
}
.aitwo{
    margin-left: 40%;
    cursor: pointer;
}
.input-edit{
    border: none;
}
.button-save{
    font-size: 110%;
    cursor: pointer;
}
