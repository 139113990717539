.joute{
    margin-left: 10%;
}
.btn-parte{
    margin-right: 50%;
    margin-top: 4%;
  }
@media screen and (max-width:700px) {
    .joute{
        margin-left: 17%;
        width: 41%;
    } 
    .btn-parte{
        margin-right: 25%;
        margin-top: 4%;
        padding-bottom: 2%;
      }
}