.co{
  padding-top: 6%;
}
.fiche{
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.stat{
  font-weight: bold;
  color: rgb(6, 218, 6);
}
.im{
  width: 100%;
  height: 55%;
}
.carde{
  border: 1px solid #ccc;
  margin-bottom: 10%;
  border-radius: 2%;
  height: 36%;
  margin-top:5%;
}
.frm{
  text-indent: 10%;
  margin-top: 2%;
  font-size: 130%;
}
.inp{
  margin-left: 5%;
  padding-left:5% ;
  color: #ccc;
}
.inpt{
  margin-left: 3%;
  padding-left:5% ;
  color: #ccc;
}
.btn-lou{
  margin-left: -70%;
  margin-top: 5%;
  background-color: none;
  border-color: green;
  color: green;
}